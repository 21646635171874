main.MuiContainer-root .MuiFormLabel-root {
    color:#ffffff!important;
}
main.MuiContainer-root .MuiOutlinedInput-notchedOutline {
    border-color:#ffffff!important;
}
main.MuiContainer-root .MuiOutlinedInput-notchedOutline legend{
  font-size:0; width: 0 !important;;
}
main.MuiContainer-root .MuiInputBase-input {
    color: #ffffff!important;
    border: 1px solid;
    border-radius: 4px;
}

main.MuiContainer-root .MuiInputBase-input:-webkit-autofill,main.MuiContainer-root .MuiInputBase-input:-webkit-autofill:hover, main.MuiContainer-root .MuiInputBase-input:-webkit-autofill:focus {
  color: #ffffff!important;
}

main.MuiContainer-root  .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -18px) scale(0.75)!important;
}
  

@media only screen and (max-width: 959.95px) {

  .MuiTableCell-root{
    min-width: 100%!important;
  }
  
}

@media print {

  .MuiTableCell-root{
    min-width: 100%!important;
    padding: 12px!important;
  }
}

@font-face {
    font-family: 'Avenir Book';
    src: url('../fonts/Avenir-Book.otf');
  }
  @font-face {
    font-family: 'Avenir Heavy';
    src: url('../fonts/Avenir-Heavy.otf');
  }
  @font-face {
    font-family: 'Avenir Medium';

    src: url('../fonts/avenir-medium.otf');
  }